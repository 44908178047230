//REMOTEFILE

import React, { useEffect, useState } from "react";
import LoadingAnimation from "./LoadingAnimation";
import { Link } from "react-router-dom";
import "./Dashboard.css";
import axios from "axios";
import azureLogo from "./assets/azure.png";
import awsLogo from "./assets/aws.svg";
import m365Logo from "./assets/m365.svg";
import ScrollingSocialMentions from "./ScrollingSocialMentions";

const API_URL =
  process.env.REACT_APP_API_URL ||
  "https://cld-srv-sts-app-01-b6d5frd0byfsegay.uksouth-01.azurewebsites.net/api";

function Dashboard({
  services,
  loading,
  isDebugMode,
  onAcknowledgeIssue,
  setServices,
}) {
  // console.log("Services prop:", services);

  const [acknowledgedIssues, setAcknowledgedIssues] = useState({});
  const [twitterMentions, setTwitterMentions] = useState(null);
  const [redditMentions, setRedditMentions] = useState(null);

  useEffect(() => {
    // console.log('Services updated in Dashboard:', services);
    fetchTwitterMentions();
    fetchRedditMentions();
  }, [services]);

  useEffect(() => {
    // console.log('Services updated in Dashboard:', services);
    const fetchAcknowledgments = async () => {
      try {
        const acknowledgments = await Promise.all(
          services.map((service) =>
            fetch(`${API_URL}/acknowledgments/${service.id}`).then((res) =>
              res.json()
            )
          )
        );
        const newAcknowledgedIssues = {};
        acknowledgments.forEach((ack, index) => {
          newAcknowledgedIssues[services[index].id] = ack;
        });
        setAcknowledgedIssues(newAcknowledgedIssues);
      } catch (error) {
        console.error("Error fetching acknowledgments:", error);
      }
    };

    if (services && services.length > 0) {
      fetchAcknowledgments();
    }
  }, [services]);

  const [logoAnimations, setLogoAnimations] = useState({});

  useEffect(() => {
    const animations = [
      "pulse",
      "rotate",
      "bounce",
      "fade",
      "flip",
      "shake",
      "swing",
    ];
    const newLogoAnimations = {};

    services.forEach((service) => {
      if (Math.random() < 0.3) {
        // 30% chance of animation
        const randomAnimation =
          animations[Math.floor(Math.random() * animations.length)];
        newLogoAnimations[service.id] = randomAnimation;
      }
    });

    setLogoAnimations(newLogoAnimations);

    const intervalId = setInterval(() => {
      setLogoAnimations((prev) => {
        const updated = { ...prev };
        services.forEach((service) => {
          if (Math.random() < 0.1) {
            // 10% chance of changing animation every interval
            if (updated[service.id]) {
              delete updated[service.id];
            } else {
              const randomAnimation =
                animations[Math.floor(Math.random() * animations.length)];
              updated[service.id] = randomAnimation;
            }
          }
        });
        return updated;
      });
    }, 1000); // Check every 5 seconds

    return () => clearInterval(intervalId);
  }, [services]);

  // useEffect(() => {
  //   fetchTwitterMentions();
  // }, []);

  const getServiceColor = (status, mentions, issues) => {
    if (
      status !== "All services operating normally" &&
      issues &&
      issues.length > 0
    )
      return "red";
    if (mentions > 10) return "amber";
    return "green";
  };

  const fetchTwitterMentions = async () => {
    try {
      const response = await axios.get(`${API_URL}/twitter-mentions`);
      const normalizedData = {
        ...response.data,
        m365: response.data.m365,
      };
      delete normalizedData.m365;
      setTwitterMentions(normalizedData);
      // console.log("Twitter mentions set:", normalizedData);
      localStorage.setItem(
        "cachedTwitterMentions",
        JSON.stringify(normalizedData)
      );
    } catch (error) {
      // console.error("Error fetching Twitter mentions:", error);
      const cachedData = localStorage.getItem("cachedTwitterMentions");
      if (cachedData) {
        setTwitterMentions(JSON.parse(cachedData));
        console.log("Using cached Twitter mentions data");
      } else {
        setTwitterMentions({
          azure: { mentions: 0 },
          aws: { mentions: 0 },
          m365: { mentions: 0 },
        });
        console.log("Using default Twitter mentions data");
      }
    }
  };

  const fetchRedditMentions = async () => {
    try {
      const response = await axios.get(`${API_URL}/reddit-mentions`);
      setRedditMentions(response.data);
      console.log("Reddit mentions set:", response.data);
      localStorage.setItem(
        "cachedRedditMentions",
        JSON.stringify(response.data)
      );
    } catch (error) {
      console.error("Error fetching Reddit mentions:", error);
      const cachedData = localStorage.getItem("cachedRedditMentions");
      if (cachedData) {
        setRedditMentions(JSON.parse(cachedData));
        console.log("Using cached Reddit mentions data");
      } else {
        setRedditMentions({
          azure: { mentions: 0 },
          aws: { mentions: 0 },
          microsoft365: { mentions: 0 },
        });
        console.log("Using default Reddit mentions data");
      }
    }
  };

  const handleAcknowledgeIssue = async (serviceId, issueId, event) => {
    event.preventDefault();
    event.stopPropagation();

    console.log(
      `Acknowledging issue for service ${serviceId}, issue ID ${issueId}`
    );

    const requestData = {
      serviceId,
      _id: issueId,
      acknowledged: true,
    };

    // console.log('Request data:', requestData);
    // console.log('API URL:', `${API_URL}/acknowledge-issue`);

    try {
      const response = await axios.post(
        `${API_URL}/acknowledge-issue`,
        requestData
      );
      console.log("Response:", response.data);

      // Update the local state to remove the acknowledged issue
      setServices((prevServices) =>
        prevServices.map((service) =>
          service.id === serviceId
            ? {
                ...service,
                issues: service.issues.filter((issue) => issue._id !== issueId),
              }
            : service
        )
      );

      // Update the acknowledged issues state
      setAcknowledgedIssues((prevAcknowledgedIssues) => ({
        ...prevAcknowledgedIssues,
        [serviceId]: {
          ...(prevAcknowledgedIssues[serviceId] || {}),
          [issueId]: true,
        },
      }));
    } catch (error) {
      console.error("Error acknowledging issue:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    }
  };

  const getServiceLogo = (serviceId) => {
    switch (serviceId) {
      case "azure":
        return azureLogo;
      case "aws":
        return awsLogo;
      case "microsoft365":
        return m365Logo;
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <LoadingAnimation />
      </div>
    );
  }

  const servicesWithoutIssues = services.filter(
    (service) =>
      service.status === "All services operating normally" ||
      !service.issues ||
      service.issues.length === 0 ||
      (service.issues &&
        service.issues.every(
          (issue) =>
            acknowledgedIssues[service.id] &&
            acknowledgedIssues[service.id][issue._id]
        ))
  );
  const servicesWithIssues = services.filter(
    (service) =>
      service.status !== "All services operating normally" &&
      service.issues &&
      service.issues.length > 0 &&
      service.issues.some(
        (issue) =>
          !(
            acknowledgedIssues[service.id] &&
            acknowledgedIssues[service.id][issue._id]
          )
      )
  );

  return (
    <div className="dashboard">
      <div className="service-grid">
        {servicesWithoutIssues.map((service) => (
          <div
            key={service.id}
            className={`service-card ${getServiceColor(
              service.status,
              ((twitterMentions && twitterMentions[service.id]?.mentions) ||
                0) +
                ((redditMentions && redditMentions[service.id]?.mentions) || 0),
              service.issues
            )} ${isDebugMode ? "clickable" : ""}`}
          >
            {isDebugMode ? (
              <Link to={`/service/${service.id}`} className="service-link">
                <div className="service-header">
                  <img
                    src={getServiceLogo(service.id)}
                    alt={`${service.name} logo`}
                    className={`service-logo ${
                      logoAnimations[service.id]
                        ? `service-logo-animate-${logoAnimations[service.id]}`
                        : ""
                    }`}
                  />
                </div>
                <p className="status">
                  Status:{" "}
                  <span
                    className={`${
                      service.issues &&
                      service.issues.length > 0 &&
                      service.issues.every(
                        (issue) =>
                          acknowledgedIssues[service.id] &&
                          acknowledgedIssues[service.id][issue._id]
                      )
                        ? "all-issues-acknowledged"
                        : service.status
                        ? `status-${service.status
                            .toLowerCase()
                            .replace(/\s+/g, "-")}`
                        : "status-unknown"
                    }`}
                  >
                    {service.issues &&
                    service.issues.length > 0 &&
                    service.issues.every(
                      (issue) =>
                        acknowledgedIssues[service.id] &&
                        acknowledgedIssues[service.id][issue._id]
                    )
                      ? "All Issues Acknowledged"
                      : service.status || "Unknown"}
                  </span>
                </p>
                <p className="mentions">
                  <img
                    src={require("./assets/x-logo.png")}
                    alt="X logo"
                    className="x-logo"
                  />
                  Mentions:{" "}
                  <span
                    className={
                      twitterMentions &&
                      twitterMentions[service.id]?.mentions > 10
                        ? "mentions-high"
                        : "mentions-normal"
                    }
                  >
                    {(twitterMentions &&
                      twitterMentions[service.id]?.mentions) ||
                      0}
                  </span>
                </p>
                <p className="mentions">
                  <img
                    src={require("./assets/reddit-logo.png")}
                    alt="Reddit logo"
                    className="reddit-logo"
                  />
                  Mentions:{" "}
                  <span
                    className={
                      redditMentions &&
                      redditMentions[service.id]?.mentions > 10
                        ? "mentions-high"
                        : "mentions-normal"
                    }
                  >
                    {(redditMentions && redditMentions[service.id]?.mentions) ||
                      0}
                  </span>
                </p>
              </Link>
            ) : (
              <>
                <div className="service-header">
                  <img
                    src={getServiceLogo(service.id)}
                    alt={`${service.name} logo`}
                    className={`service-logo ${
                      logoAnimations[service.id]
                        ? `service-logo-animate-${logoAnimations[service.id]}`
                        : ""
                    }`}
                  />
                </div>
                <p className="status">
                  Status:{" "}
                  <span
                    className={`${
                      service.issues &&
                      service.issues.length > 0 &&
                      service.issues.every(
                        (issue) =>
                          acknowledgedIssues[service.id] &&
                          acknowledgedIssues[service.id][issue._id]
                      )
                        ? "all-issues-acknowledged"
                        : service.status
                        ? `status-${service.status
                            .toLowerCase()
                            .replace(/\s+/g, "-")}`
                        : "status-unknown"
                    }`}
                  >
                    {service.issues &&
                    service.issues.length > 0 &&
                    service.issues.every(
                      (issue) =>
                        acknowledgedIssues[service.id] &&
                        acknowledgedIssues[service.id][issue._id]
                    )
                      ? "All Issues Acknowledged"
                      : service.status || "Unknown"}
                  </span>
                </p>
                <p className="mentions">
                  <img
                    src={require("./assets/x-logo.png")}
                    alt="X logo"
                    className="x-logo"
                  />
                  Mentions:{" "}
                  <span
                    className={
                      twitterMentions &&
                      twitterMentions[service.id]?.mentions > 10
                        ? "mentions-high"
                        : "mentions-normal"
                    }
                  >
                    {(twitterMentions &&
                      twitterMentions[service.id]?.mentions) ||
                      0}
                  </span>
                </p>
                <p className="mentions">
                  <img
                    src={require("./assets/reddit-logo.png")}
                    alt="Reddit logo"
                    className="reddit-logo"
                  />
                  Mentions:{" "}
                  <span
                    className={
                      redditMentions &&
                      redditMentions[service.id]?.mentions > 10
                        ? "mentions-high"
                        : "mentions-normal"
                    }
                  >
                    {(redditMentions && redditMentions[service.id]?.mentions) ||
                      0}
                  </span>
                </p>
              </>
            )}
          </div>
        ))}
      </div>
      {servicesWithIssues.length > 0 && (
        <div className="services-with-issues">
          <h2>Services with Issues</h2>
          <div className="issues-grid">
            {servicesWithIssues.map((service) => (
              <div
                key={service.id}
                className={`service-card ${getServiceColor(
                  service.status,
                  ((twitterMentions && twitterMentions[service.id]?.mentions) ||
                    0) +
                    ((redditMentions && redditMentions[service.id]?.mentions) ||
                      0),
                  service.issues
                )} ${isDebugMode ? "clickable" : ""}`}
              >
                {isDebugMode ? (
                  <Link to={`/service/${service.id}`} className="service-link">
                    <div className="service-content">
                      <div className="service-header">
                        <img
                          src={getServiceLogo(service.id)}
                          alt={`${service.name} logo`}
                          className={`service-logo ${
                            logoAnimations[service.id]
                              ? `service-logo-animate-${
                                  logoAnimations[service.id]
                                }`
                              : ""
                          }`}
                        />
                      </div>
                      <p className="status">
                        Status:{" "}
                        <span
                          className={`status-${
                            (service.status &&
                              service.status
                                .toLowerCase()
                                .replace(/\s+/g, "-")) ||
                            "unknown"
                          }`}
                        >
                          {service.status || "Unknown"}
                        </span>
                      </p>{" "}
                      <p className="mentions">
                        <img
                          src={require("./assets/x-logo.png")}
                          alt="X logo"
                          className="x-logo"
                        />
                        Mentions:{" "}
                        <span
                          className={
                            twitterMentions &&
                            twitterMentions[service.id]?.mentions > 10
                              ? "mentions-high"
                              : "mentions-normal"
                          }
                        >
                          {(twitterMentions &&
                            twitterMentions[service.id]?.mentions) ||
                            0}
                        </span>
                      </p>
                      <p className="mentions">
                        <img
                          src={require("./assets/reddit-logo.png")}
                          alt="Reddit logo"
                          className="reddit-logo"
                        />
                        Mentions:{" "}
                        <span
                          className={
                            redditMentions &&
                            redditMentions[service.id]?.mentions > 10
                              ? "mentions-high"
                              : "mentions-normal"
                          }
                        >
                          {(redditMentions &&
                            redditMentions[service.id]?.mentions) ||
                            0}
                        </span>
                      </p>
                      <div className="service-issues">
                        <div className="issues-header">
                          <h3>Current Issues:</h3>
                          <span className="issues-header">
                            {
                              service.issues.filter(
                                (issue) =>
                                  !issue.resolved &&
                                  !(
                                    acknowledgedIssues[service.id] &&
                                    acknowledgedIssues[service.id][issue._id]
                                  )
                              ).length
                            }
                          </span>
                          {service.issues.filter((issue) => {
                            const isAcknowledged =
                              acknowledgedIssues[service.id] &&
                              acknowledgedIssues[service.id][issue._id];
                            return !isAcknowledged;
                          }).length === 0 && (
                            <div className="all-issues-acknowledged">
                              <p>All Issues Acknowledged</p>
                            </div>
                          )}
                        </div>
                        {service.issues.filter((issue) => {
                          const isAcknowledged =
                            acknowledgedIssues[service.id] &&
                            acknowledgedIssues[service.id][issue._id];
                          return !isAcknowledged;
                        }).length > 0 ? (
                          <div className="issues-table-container">
                            <table className="issues-table">
                              <thead>
                                <tr>
                                  <th>Published</th>
                                  <th>Title</th>
                                  <th>Description</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {service.issues
                                  .filter((issue) => {
                                    const isAcknowledged =
                                      acknowledgedIssues[service.id] &&
                                      acknowledgedIssues[service.id][issue._id];
                                    return !isAcknowledged;
                                  })
                                  .sort(
                                    (a, b) =>
                                      new Date(b.pubDate) - new Date(a.pubDate)
                                  )
                                  .map((issue) => (
                                    <tr key={issue._id}>
                                      <td>
                                        {new Date(
                                          issue.pubDate
                                        ).toLocaleDateString()}
                                      </td>
                                      <td>{issue.title}</td>
                                      <td>
                                        {issue.impactDescription ? (
                                          <p>{issue.impactDescription}</p>
                                        ) : (
                                          <p>{issue.description}</p>
                                        )}
                                        {console.log(
                                          "Issue data:",
                                          JSON.stringify(issue, null, 2)
                                        )}
                                        <a
                                          href={issue.link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {/* <a href="#" onClick={(e) => { e.preventDefault(); console.log('Issue details:', issue); }} target="_blank" rel="noopener noreferrer"> */}
                                          More Info
                                        </a>
                                      </td>
                                      <td>
                                        <button
                                          onClick={(e) => {
                                            console.log(
                                              "ZZZ Acknowledging issue (detailed):",
                                              {
                                                serviceId: service.id,
                                                issueId: issue._id,
                                                issueIdType: typeof issue._id,
                                                issue: JSON.stringify(issue),
                                                acknowledged: true,
                                              }
                                            );
                                            // e.preventDefault();
                                            handleAcknowledgeIssue(
                                              service.id,
                                              issue._id,
                                              e
                                            );
                                          }}
                                          className="acknowledge-button"
                                        >
                                          Acknowledge
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div className="service-content">
                    <div className="service-header">
                      <img
                        src={getServiceLogo(service.id)}
                        alt={`${service.name} logo`}
                        className={`service-logo ${
                          logoAnimations[service.id]
                            ? `service-logo-animate-${
                                logoAnimations[service.id]
                              }`
                            : ""
                        }`}
                      />
                    </div>
                    <p className="status">
                      Status:{" "}
                      <span
                        className={`status-${
                          (service.status &&
                            service.status
                              .toLowerCase()
                              .replace(/\s+/g, "-")) ||
                          "unknown"
                        }`}
                      >
                        {service.status || "Unknown"}
                      </span>
                    </p>{" "}
                    <p className="mentions">
                      <img
                        src={require("./assets/x-logo.png")}
                        alt="X logo"
                        className="x-logo"
                      />
                      Mentions:{" "}
                      <span
                        className={
                          twitterMentions &&
                          twitterMentions[service.id]?.mentions > 10
                            ? "mentions-high"
                            : "mentions-normal"
                        }
                      >
                        {(twitterMentions &&
                          twitterMentions[service.id]?.mentions) ||
                          0}
                      </span>
                    </p>
                    <p className="mentions">
                      <img
                        src={require("./assets/reddit-logo.png")}
                        alt="Reddit logo"
                        className="reddit-logo"
                      />
                      Mentions:{" "}
                      <span
                        className={
                          redditMentions &&
                          redditMentions[service.id]?.mentions > 10
                            ? "mentions-high"
                            : "mentions-normal"
                        }
                      >
                        {(redditMentions &&
                          redditMentions[service.id]?.mentions) ||
                          0}
                      </span>
                    </p>
                    <div className="service-issues">
                      <div className="issues-header">
                        <h3>Current Issues:</h3>
                        <span className="issues-header">
                          {
                            service.issues.filter(
                              (issue) =>
                                !issue.resolved &&
                                !(
                                  acknowledgedIssues[service.id] &&
                                  acknowledgedIssues[service.id][issue._id]
                                )
                            ).length
                          }
                        </span>
                        {service.issues.filter((issue) => {
                          const isAcknowledged =
                            acknowledgedIssues[service.id] &&
                            acknowledgedIssues[service.id][issue._id];
                          return !isAcknowledged;
                        }).length === 0 && (
                          <div className="all-issues-acknowledged">
                            <p>All Issues Acknowledged</p>
                          </div>
                        )}
                      </div>
                      {service.issues.filter((issue) => {
                        const isAcknowledged =
                          acknowledgedIssues[service.id] &&
                          acknowledgedIssues[service.id][issue._id];
                        return !isAcknowledged;
                      }).length > 0 ? (
                        <div className="issues-table-container">
                          <table className="issues-table">
                            <thead>
                              <tr>
                                <th>Published</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {service.issues
                                .filter((issue) => {
                                  const isAcknowledged =
                                    acknowledgedIssues[service.id] &&
                                    acknowledgedIssues[service.id][issue._id];
                                  return !isAcknowledged;
                                })
                                .sort(
                                  (a, b) =>
                                    new Date(b.pubDate) - new Date(a.pubDate)
                                )
                                .map((issue) => (
                                  <tr key={issue._id}>
                                    <td>
                                      {new Date(
                                        issue.pubDate
                                      ).toLocaleDateString()}
                                    </td>
                                    <td>{issue.title}</td>
                                    <td>
                                      {issue.impactDescription ? (
                                        <p>{issue.impactDescription}</p>
                                      ) : (
                                        <p>{issue.description}</p>
                                      )}
                                      {console.log(
                                        "Issue data:",
                                        JSON.stringify(issue, null, 2)
                                      )}
                                      <a
                                        href={issue.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {/* <a href="#" onClick={(e) => { e.preventDefault(); console.log('Issue details:', issue); }} target="_blank" rel="noopener noreferrer"> */}
                                        More Info
                                      </a>
                                    </td>
                                    <td>
                                      <button
                                        onClick={(e) => {
                                          // e.preventDefault();
                                          handleAcknowledgeIssue(
                                            service.id,
                                            issue._id,
                                            e
                                          );
                                        }}
                                        className="acknowledge-button"
                                      >
                                        Acknowledge
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {(twitterMentions || redditMentions) && (
        <ScrollingSocialMentions
          mentions={[
            ...(twitterMentions
              ? [
                  ...(twitterMentions.azure?.tweets || []),
                  ...(twitterMentions.aws?.tweets || []),
                  ...(twitterMentions.Microsoft365?.tweets || []),
                ]
              : []),
            ...(redditMentions
              ? [
                  ...(redditMentions.azure?.posts || []),
                  ...(redditMentions.aws?.posts || []),
                  ...(redditMentions.Microsoft365?.posts || []),
                ]
              : []),
          ].map((mention) => ({
            ...mention,
            source: mention.hasOwnProperty("text") ? "Twitter" : "Reddit",
          }))}
        />
      )}
    </div>
  );
}

export default Dashboard;
