//REMOTEFILE

import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "./ServiceDetail.css";
import azureLogo from "./assets/azure.png";
import awsLogo from "./assets/aws.svg";
import m365Logo from "./assets/m365.svg";

const API_URL =
  process.env.REACT_APP_API_URL ||
  "https://cld-srv-sts-app-01-b6d5frd0byfsegay.uksouth-01.azurewebsites.net/api";

function ServiceDetail({
  services,
  isDebugMode,
  debugInfo,
  onAcknowledgeIssue,
  onUnacknowledgeIssue,
  setServices,
}) {
  const { id } = useParams();
  const [historicIssues, setHistoricIssues] = useState([]);
  const [timelineDays, setTimelineDays] = useState(30);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [twitterMentions, setTwitterMentions] = useState([]);
  const [redditMentions, setRedditMentions] = useState([]);

  // const [activeIssues, setActiveIssues] = useState([]);
  // const [resolvedIssues, setResolvedIssues] = useState([]);

  const getServiceLogo = (serviceId) => {
    switch (serviceId) {
      case "azure":
        return azureLogo;
      case "aws":
        return awsLogo;
      case "microsoft365":
        return m365Logo;
      default:
        return null;
    }
  };

  const fetchTwitterMentions = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/twitter-mentions`);
      console.log("Twitter mentions response:", response.data);
      const serviceMentions =
        response.data[id] || response.data[id.toLowerCase()] || {};
      setTwitterMentions(serviceMentions);
    } catch (error) {
      console.error("Error fetching Twitter mentions:", error);
      setTwitterMentions({});
    }
  }, [id]);

  const fetchRedditMentions = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/reddit-mentions`);
      console.log("Reddit mentions response:", response.data);
      const serviceMentions =
        response.data[id] || response.data[id.toLowerCase()] || {};
      setRedditMentions(serviceMentions);
    } catch (error) {
      console.error("Error fetching Reddit mentions:", error);
      setRedditMentions({});
    }
  }, [id]);

  useEffect(() => {
    fetchTwitterMentions();
    fetchRedditMentions();
  }, [fetchTwitterMentions, fetchRedditMentions]);

  // useEffect(() => {
  //   const fetchIssues = async () => {
  //     try {
  //       const response = await axios.get(`${API_URL}/issues/${id}`);
  //       setActiveIssues(response.data.filter(issue => !issue.resolved));
  //       setResolvedIssues(response.data.filter(issue => issue.resolved));
  //     } catch (error) {
  //       console.error('Error fetching issues:', error);
  //     }
  //   };

  //   fetchIssues();
  // }, [id]);

  useEffect(() => {
    const fetchHistoricIssues = async () => {
      try {
        const normalizedId = id.toLowerCase(); // or id.toUpperCase() if your DB uses uppercase
        console.log(
          `Fetching historic issues for ${normalizedId} with timelineDays: ${timelineDays}`
        );
        const response = await axios.get(
          `${API_URL}/historic-issues/${normalizedId}?days=${timelineDays}`
        );
        console.log("Historic issues response:", response.data);
        console.log("SERVICE:", normalizedId);
        setHistoricIssues(response.data);
      } catch (error) {
        console.error("Error fetching historic issues:", error);
      }
    };

    fetchHistoricIssues();
  }, [id, timelineDays]);

  useEffect(() => {
    const fetchCurrentStatus = async () => {
      try {
        const normalizedId = id.toLowerCase();
        const response = await axios.get(
          `${API_URL}/service-details/${normalizedId}`
        );

        setCurrentStatus((prevStatus) => ({
          ...prevStatus,
          service: normalizedId,
          status:
            response.data.latestIssue.length > 0
              ? "Service disruption"
              : "All services operating normally",
          issues: response.data.latestIssue || [],
          acknowledgments: response.data.acknowledgments || {},
        }));

        console.log("Current status response:", response.data);
      } catch (error) {
        console.error(`Error fetching ${id} service details:`, error);
      }
    };

    fetchCurrentStatus();
  }, [id]);
  useEffect(() => {
    const fetchCurrentStatus = async () => {
      try {
        const normalizedId = id.toLowerCase();
        const response = await axios.get(
          `${API_URL}/service-details/${normalizedId}`
        );

        setCurrentStatus((prevStatus) => ({
          ...prevStatus,
          service: normalizedId,
          status:
            response.data.latestIssue.filter((issue) => !issue.resolved)
              .length > 0
              ? "Service disruption"
              : "All services operating normally",
          issues: response.data.latestIssue
            ? response.data.latestIssue.filter((issue) => !issue.resolved)
            : [],
          acknowledgments: response.data.acknowledgments || {},
        }));

        console.log("ZZZZZZZ Current status response:", response.data);
      } catch (error) {
        console.error(`Error fetching ${id} service details:`, error);
      }
    };

    fetchCurrentStatus();
  }, [id]);

  useEffect(() => {
    let isMounted = true;

    const fetchAcknowledgments = async () => {
      if (currentStatus?.issues?.length > 0) {
        try {
          const response = await axios.get(`${API_URL}/acknowledgments/${id}`);
          if (isMounted) {
            setCurrentStatus((prevStatus) => ({
              ...prevStatus,
              issues: prevStatus.issues.map((issue, index) => ({
                ...issue,
                acknowledged: response.data[index] || false,
              })),
            }));
          }
        } catch (error) {
          console.error("Error fetching acknowledgments:", error);
        }
      }
    };

    fetchAcknowledgments();

    return () => {
      isMounted = false;
    };
  }, [currentStatus?.issues?.length, id]);

  if (!currentStatus) {
    return <div>Loading...</div>;
  }

  const handleAcknowledgeIssue = async (serviceId, issueId, event) => {
    // event.preventDefault();
    // console.log(`Acknowledging issue for service ${serviceId}, issue ID ${issueId}`);

    const requestData = {
      serviceId: currentStatus.service,
      _id: issueId,
      acknowledged: true,
    };

    // console.log('Request data:', requestData);
    // console.log('API URL:', `${API_URL}/acknowledge-issue`);

    try {
      const response = await axios.post(
        `${API_URL}/acknowledge-issue`,
        requestData
      );
      console.log("Response:", response.data);

      setCurrentStatus((prevStatus) => ({
        ...prevStatus,
        issues: prevStatus.issues.map((issue) =>
          issue._id === issueId ? { ...issue, acknowledged: true } : issue
        ),
      }));
    } catch (error) {
      console.error("Error acknowledging issue:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    }
  };

  const handleUnacknowledgeIssue = async (serviceId, issueId, event) => {
    // event.preventDefault();
    // console.log(`Unacknowledging issue for service ${serviceId}, issue ID ${issueId}`);

    const requestData = {
      serviceId: currentStatus.service,
      _id: issueId,
      acknowledged: false,
    };

    console.log("Request data:", requestData);
    console.log("API URL:", `${API_URL}/acknowledge-issue`);

    try {
      const response = await axios.post(
        `${API_URL}/acknowledge-issue`,
        requestData
      );
      console.log("Response:", response.data);

      setCurrentStatus((prevStatus) => ({
        ...prevStatus,
        issues: prevStatus.issues.map((issue) =>
          issue._id === issueId ? { ...issue, acknowledged: false } : issue
        ),
      }));
    } catch (error) {
      console.error("Error unacknowledging issue:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    }
  };

  return (
    <div className="service-detail">
      <header className="service-header">
        <Link to="/" className="back-button">
          Back to Dashboard
        </Link>
        <img
          src={getServiceLogo(currentStatus.service.toLowerCase())}
          alt={`${currentStatus.service.toLowerCase()} logo`}
          className="service-logo"
        />
        <p className="status">
          Current Status:{" "}
          <span className={`status-${currentStatus.status.toLowerCase()}`}>
            {currentStatus.status}
          </span>
        </p>
      </header>

      <div className="service-content">
        <div className="main-column">
          <section className="service-issues">
            <h3>Current Issues</h3>
            <div className="issues-table-container">
              <table className="issues-table">
                <thead>
                  <tr>
                    <th>Published</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentStatus.issues && currentStatus.issues.length > 0 ? (
                    (console.log("currentStatus.issues:", currentStatus.issues),
                    currentStatus.issues
                      .sort((a, b) => new Date(b.pubDate) - new Date(a.pubDate))
                      .map((issue, index) => (
                        <tr key={index}>
                          <td>
                            {new Date(issue.pubDate).toLocaleDateString()}
                          </td>
                          <td>{issue.title}</td>
                          <td>
                            {issue.impactDescription
                              ? issue.impactDescription
                              : issue.description}
                            <a
                              href={issue.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Details
                            </a>
                          </td>
                          <td>
                            {issue.acknowledged ? "Acknowledged" : "Active"}
                          </td>
                          <td>
                            {issue.acknowledged ? (
                              <button
                                onClick={() =>
                                  handleUnacknowledgeIssue(
                                    setCurrentStatus.id,
                                    issue._id
                                  )
                                }
                              >
                                Unacknowledge
                              </button>
                            ) : (
                              <button
                                onClick={() =>
                                  handleAcknowledgeIssue(
                                    setCurrentStatus.id,
                                    issue._id
                                  )
                                }
                              >
                                Acknowledge
                              </button>
                            )}
                          </td>
                        </tr>
                      )))
                  ) : (
                    <tr>
                      <td colSpan="4">No current issues.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </section>

          <section className="historic-issues">
            <h3>Historic Issues</h3>
            <div className="timeline-control">
              <label htmlFor="timelineDays">Show issues from the last:</label>
              <select
                id="timelineDays"
                value={timelineDays}
                onChange={(e) => setTimelineDays(Number(e.target.value))}
              >
                <option value={30}>30 days</option>
                <option value={60}>60 days</option>
                <option value={90}>90 days</option>
              </select>
            </div>
            <div className="historic-issues-table-container">
              <table className="historic-issues-table">
                <thead>
                  <tr>
                    <th>Published</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {historicIssues
                    .sort((a, b) => new Date(b.pubDate) - new Date(a.pubDate))
                    .map((issue, index) => (
                      // console.log('historicIssues:', historicIssues),
                      <tr key={index}>
                        <td>{new Date(issue.pubDate).toLocaleDateString()}</td>
                        <td>{issue.title}</td>
                        <td>
                          {issue.impactDescription
                            ? issue.impactDescription
                            : issue.description}
                          <a
                            href={issue.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Details
                          </a>
                        </td>
                        <td>{issue.resolved ? "Resolved" : "Active"}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </section>
        </div>

        <div className="side-column">
          <section className="tweets">
            <h3>Social Mentions</h3>
            {(twitterMentions.tweets && twitterMentions.tweets.length > 0) ||
            (redditMentions.posts && redditMentions.posts.length > 0) ? (
              <ul className="tweet-list">
                {twitterMentions.tweets &&
                  twitterMentions.tweets.map((tweet, index) => (
                    <li key={`twitter-${index}`} className="tweet-item">
                      <a
                        href={`https://twitter.com/user/status/${tweet.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p>{tweet.text}</p>
                        <span className="tweet-date">
                          {new Date(tweet.created_at).toLocaleString()}
                        </span>
                        <p>Sentiment: {tweet.sentiment}</p>
                        <p>
                          Matched keywords: {tweet.matchedKeywords.join(", ")}
                        </p>
                      </a>
                    </li>
                  ))}
                {redditMentions.posts &&
                  redditMentions.posts.map((post, index) => (
                    <li key={`reddit-${index}`} className="tweet-item">
                      <a
                        href={post.url || "#"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p>{post.title || "No title available"}</p>
                        <span className="tweet-date">
                          {post.created
                            ? new Date(post.created).toLocaleString()
                            : post.created_utc
                            ? new Date(post.created_utc * 1000).toLocaleString()
                            : "Date unavailable"}
                        </span>
                        <p>
                          Sentiment:{" "}
                          {post.sentiment !== undefined
                            ? post.sentiment
                            : "N/A"}
                        </p>
                        <p>
                          Matched keywords:{" "}
                          {Array.isArray(post.matchedKeywords)
                            ? post.matchedKeywords.join(", ")
                            : "N/A"}
                        </p>
                      </a>
                    </li>
                  ))}
              </ul>
            ) : (
              <p>No social mentions to report at this time.</p>
            )}
          </section>
        </div>
      </div>
    </div>
  );
}

export default ServiceDetail;
