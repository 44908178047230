import React, { useState, useEffect } from 'react';
import './LoadingAnimation.css';

const LoadingAnimation = () => {
  const [stage, setStage] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setStage((prevStage) => (prevStage + 1) % 6);
    }, 10000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="loading-container">
      <div className={`loading-animation stage-${stage}`}>
        <div className={`loading-text ${stage >= 2 ? 'cracked' : ''}`}>
          {stage <= 1 && (stage === 0 ? "Loading..." : "Still loading...")}
          {stage >= 2 && stage <= 4 && (
            <>
              <span className="crack-text">Still loading...</span>
              <div className="crack-overlay"></div>
            </>
          )}
          {stage === 5 && "I think technical assistance is needed"}
        </div>
        {stage === 4 && <div className="flashing-screen"></div>}
      </div>
    </div>
  );
};

export default LoadingAnimation;