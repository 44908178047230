import React, { useState } from "react";
import VersionInfo, { version } from "./VersionInfo";

const API_URL =
  process.env.REACT_APP_API_URL ||
  "https://cld-srv-sts-app-01-b6d5frd0byfsegay.uksouth-01.azurewebsites.net/api";

function Menu({
  isOpen,
  toggleMenu,
  refreshInterval,
  twitterLookbackPeriod,
  handleRefreshIntervalChange,
  handleTwitterLookbackChange,
  toggleDebugMode,
  isDebugMode,
  saveSettings,
  saveMessage,
}) {
  const [feedbackContent, setFeedbackContent] = useState("");
  const [feedbackType, setFeedbackType] = useState("");
  const [feedbackError, setFeedbackError] = useState("");
  const [feedbackSuccess, setFeedbackSuccess] = useState("");

  if (!isOpen) return null;

  const handleFeedbackSubmit = async () => {
    if (feedbackContent.length < 10 || !feedbackType) {
      setFeedbackError(
        "Please enter at least 10 characters and select a feedback type."
      );
      return;
    }

    try {
      const response = await fetch(`${API_URL}/feedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content: feedbackContent, type: feedbackType }),
      });

      if (response.ok) {
        setFeedbackSuccess("Feedback submitted successfully!");
        setFeedbackContent("");
        setFeedbackType("");
        setFeedbackError("");
      } else {
        throw new Error("Failed to submit feedback");
      }
    } catch (error) {
      setFeedbackError(
        "An error occurred while submitting feedback. Please try again."
      );
    }
  };

  return (
    <>
      <div className={`menu ${isOpen ? "open" : ""}`}>
        <div className="menu-content">
          <div className="menu-close" onClick={toggleMenu}>
            ×
          </div>
          <VersionInfo version={version} />
          <h2>Settings</h2>
          <div className="menu-item">
            <label>
              Refresh Interval (seconds):
              <input
                type="number"
                value={refreshInterval}
                onChange={handleRefreshIntervalChange}
                min="5"
              />
            </label>
          </div>
          <div className="menu-item">
            <label>
              X Lookback Period:
              <select
                value={twitterLookbackPeriod}
                onChange={handleTwitterLookbackChange}
              >
                <option value="30">30 minutes</option>
                <option value="60">1 hour</option>
                <option value="120">2 hours</option>
                <option value="240">4 hours</option>
              </select>
            </label>
          </div>
          <div className="menu-item debug-mode">
            <span>Debug Mode:</span>
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={isDebugMode}
                onChange={toggleDebugMode}
              />
              <span className="slider"></span>
            </label>
          </div>
          <div className="menu-item">
            <button onClick={saveSettings}>Save Settings</button>
          </div>

          <h2>Feedback</h2>
          <div className="menu-item">
            <textarea
              value={feedbackContent}
              onChange={(e) => setFeedbackContent(e.target.value)}
              placeholder="Enter your feedback here (min 10 characters)"
              rows={4}
            />
          </div>
          <div className="menu-item">
            <select
              value={feedbackType}
              onChange={(e) => setFeedbackType(e.target.value)}
            >
              <option value="">Select feedback type</option>
              <option value="positive">Positive</option>
              <option value="neutral">Neutral</option>
              <option value="negative">Negative</option>
            </select>
          </div>
          <div className="menu-item">
            <button onClick={handleFeedbackSubmit}>Submit Feedback</button>
          </div>
          {feedbackError && (
            <div className="error-message">{feedbackError}</div>
          )}
          {feedbackSuccess && (
            <div className="success-message">{feedbackSuccess}</div>
          )}
        </div>
      </div>
      {saveMessage && <div className="save-message">{saveMessage}</div>}
    </>
  );
}

export default Menu;
