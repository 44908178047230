import React, { useState } from "react";

export const version = "1.0.9";

const VersionInfo = ({ version }) => {
  const [showUpdates, setShowUpdates] = useState(false);

  const toggleUpdates = () => {
    setShowUpdates(!showUpdates);
    const menu = document.querySelector(".menu");
    const menuItems = document.querySelectorAll(".menu-item");
    if (menu) {
      menu.style.width = showUpdates ? "300px" : "500px";
      menuItems.forEach((item) => {
        item.style.opacity = showUpdates ? "1" : "0.5";
      });
    }
  };

  const updates = ["Incorporated Reddit into solution"];

  return (
    <div className="version-info">
      <span onClick={toggleUpdates} style={{ cursor: "pointer" }}>
        Version {version}
      </span>
      {showUpdates && (
        <div className="update-list">
          <h3>Updates in this version:</h3>
          <a
            href="https://agreeable-smoke-02a38e703.5.azurestaticapps.net/#version"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontSize: "0.83em",
              fontWeight: "bold",
              textDecoration: "none",
              color: "blue",
            }}
          >
            Version History
          </a>{" "}
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {updates.map((update, index) => (
              <li
                key={index}
                style={{
                  marginBottom: "10px",
                  padding: "8px",
                  backgroundColor: "#f0f0f0",
                  borderRadius: "4px",
                  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                }}
              >
                {update}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default VersionInfo;
