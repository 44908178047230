//REMOTEFILE

import React, { useState, useEffect, useRef, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
import Dashboard from "./Dashboard";
import ServiceDetail from "./ServiceDetail";
import Menu from "./Menu";
import netcompanyLogo from "./assets/netcompany.svg";
import "./App.css";

const API_URL =
  process.env.REACT_APP_API_URL ||
  "https://cld-srv-sts-app-01-b6d5frd0byfsegay.uksouth-01.azurewebsites.net/api";

function App() {
  // console.log('API_URL:', API_URL);
  const [settings, setSettings] = useState({ refreshInterval: 60 });
  const [unsavedSettings, setUnsavedSettings] = useState(null);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(() => {
    const saved = localStorage.getItem("isMenuOpen");
    return saved ? JSON.parse(saved) : false;
  });
  const [isDebugMode, setIsDebugMode] = useState(false);
  const [debugInfo, setDebugInfo] = useState({});
  const intervalRef = useRef(null);
  const [twitterLookbackPeriod, setTwitterLookbackPeriod] = useState(60);

  const fetchData = useCallback(async () => {
    // console.log(`Page refreshed at ${new Date().toLocaleTimeString()} - Refresh interval: ${settings.refreshInterval}s`);
    setLoading(true);
    const startTime = performance.now();
    try {
      const [
        azureResponse,
        awsResponse,
        m365Response,
        azureAcknowledgments,
        awsAcknowledgments,
        m365Acknowledgments,
      ] = await Promise.all([
        axios.get(`${API_URL}/azure-status`),
        axios.get(`${API_URL}/aws-status`),
        axios.get(`${API_URL}/microsoft365-status`),
        axios.get(`${API_URL}/twitter-mentions`),
        axios.get(`${API_URL}/acknowledgments/azure`),
        axios.get(`${API_URL}/acknowledgments/aws`),
        axios.get(`${API_URL}/acknowledgments/microsoft365`),
        fetchAcknowledgments("azure"),
        fetchAcknowledgments("aws"),
        fetchAcknowledgments("microsoft365"),
      ]);

      // console.log('Azure Acknowledgments:', azureAcknowledgments.data);
      // console.log('AWS Acknowledgments:', awsAcknowledgments.data);
      // console.log('Microsoft365 Acknowledgments:', m365Acknowledgments.data);
      // console.log('Azure Acknowledgments:', azureAcknowledgments);
      // console.log('AWS Acknowledgments:', awsAcknowledgments);
      // console.log('Microsoft365 Acknowledgments:', m365Acknowledgments);
      const endTime = performance.now();
      const fetchDuration = endTime - startTime;

      setServices([
        {
          id: "azure",
          name: "Azure",
          status: azureResponse.data.status,
          health:
            azureResponse.data.status === "All services operating normally"
              ? "Healthy"
              : "Unhealthy",
          issues: azureResponse.data.issues.map((issue, index) => ({
            ...issue,
            acknowledged: azureAcknowledgments[index] || false,
          })),
          mentions: azureResponse.data.mentions || 0,
        },
        {
          id: "aws",
          name: "AWS",
          status: awsResponse.data.status,
          health:
            awsResponse.data.status === "All services operating normally"
              ? "Healthy"
              : "Unhealthy",
          issues: awsResponse.data.issues.map((issue, index) => ({
            ...issue,
            acknowledged: awsAcknowledgments[index] || false,
          })),
          mentions: awsResponse.data.mentions || 0,
        },
        {
          id: "microsoft365",
          name: "Microsoft365",
          status: m365Response.data.status,
          health:
            m365Response.data.status === "All services operating normally"
              ? "Healthy"
              : "Unhealthy",
          issues: m365Response.data.issues.map((issue, index) => ({
            ...issue,
            acknowledged: m365Acknowledgments[index] || false,
          })),
          mentions: m365Response.data.mentions || 0,
        },
      ]);

      const updatedServices = [
        {
          id: "azure",
          health:
            azureResponse.data.status === "All services operating normally"
              ? "Healthy"
              : "Unhealthy",
        },
        {
          id: "aws",
          health:
            awsResponse.data.status === "All services operating normally"
              ? "Healthy"
              : "Unhealthy",
        },
        {
          id: "microsoft365",
          health:
            m365Response.data.status === "All services operating normally"
              ? "Healthy"
              : "Unhealthy",
        },
      ];

      await Promise.all(
        updatedServices.map(async (service) => {
          try {
            await axios.post(`${API_URL}/update-health-status`, {
              serviceId: service.id,
              health: service.health,
            });
            console.log(
              `Updated health status for ${service.id} to ${service.health}`
            );
          } catch (error) {
            console.error(
              `Error updating health status for ${service.id}:`,
              error
            );
          }
        })
      );

      // console.log('Updated services state:', services);
      setDebugInfo({
        fetchDuration: fetchDuration.toFixed(2),
        lastFetchTime: new Date().toISOString(),
        azureStatus: {
          url: `${API_URL}/azure-status`,
          responseTime: azureResponse.headers["x-response-time"] || "N/A",
          criteria: "Parsing RSS feed for service disruptions",
        },
        awsStatus: {
          url: `${API_URL}/aws-status`,
          responseTime: awsResponse.headers["x-response-time"] || "N/A",
          criteria: "Parsing RSS feed for service disruptions",
        },
        m365Status: {
          url: `${API_URL}/microsoft365-status`,
          responseTime: m365Response.headers["x-response-time"] || "N/A",
          criteria: "Parsing RSS feed for service disruptions",
        },
        refreshInterval: settings.refreshInterval,
        browserInfo: {
          userAgent: navigator.userAgent,
          language: navigator.language,
          onLine: navigator.onLine,
        },
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setDebugInfo((prevInfo) => ({
        ...prevInfo,
        lastError: error.message,
        lastErrorTime: new Date().toISOString(),
      }));
    }
    setLoading(false);
  }, [settings.refreshInterval]);

  useEffect(() => {
    loadSettings();
  }, []);

  useEffect(() => {
    console.log(
      "Services issues updated:",
      services.map((service) => ({
        id: service.id,
        issues: service.issues,
      }))
    );
  }, [services]);

  useEffect(() => {
    localStorage.setItem("isMenuOpen", JSON.stringify(isMenuOpen));
  }, [isMenuOpen]);

  useEffect(() => {
    // console.log('Debug mode changed:', isDebugMode);
  }, [isDebugMode]);

  useEffect(() => {
    fetchData();

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(
      fetchData,
      settings.refreshInterval * 1000
    );

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [fetchData, settings.refreshInterval]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${API_URL}/settings`);
        setTwitterLookbackPeriod(response.data.twitterLookbackPeriod || 60);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };
    fetchSettings();
  }, []);

  <Menu
    twitterLookbackPeriod={twitterLookbackPeriod}
    setTwitterLookbackPeriod={setTwitterLookbackPeriod}
  />;

  const loadSettings = async () => {
    try {
      const response = await axios.get(`${API_URL}/settings`);
      setSettings(response.data);
      console.log(
        `Settings loaded - Refresh Interval: ${response.data.refreshInterval}`
      );
    } catch (error) {
      console.error("Error loading settings:", error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const toggleDebugMode = () => {
    // console.log("Debug mode toggled in App");
    setIsDebugMode((prevMode) => !prevMode);
  };

  const handleSaveSettings = async () => {
    console.log("Save Settings clicked");
    if (unsavedSettings) {
      const newSettings = {
        ...settings,
        ...unsavedSettings,
        twitterLookbackPeriod,
      };
      try {
        await axios.post(`${API_URL}/settings`, newSettings);
        setSettings(newSettings);
        setUnsavedSettings(null);
        console.log("Settings saved successfully");
      } catch (error) {
        console.error("Error saving settings:", error);
      }
    }
  };

  const handleTwitterLookbackChange = (e) => {
    const newValue = parseInt(e.target.value);
    if (!isNaN(newValue) && newValue > 0) {
      setTwitterLookbackPeriod(newValue);
      setUnsavedSettings((prev) => ({
        ...prev,
        twitterLookbackPeriod: newValue,
      }));
      console.log("Twitter lookback period changed to:", newValue);
    }
  };

  const handleAcknowledgeIssue = async (serviceId, _id) => {
    // console.log(`Toggling acknowledgment for service ${serviceId}, issue index ${_id}`);
    try {
      const service = services.find((s) => s.id === serviceId);
      const issue = service.issues[_id];
      const newAcknowledgedStatus = !issue.acknowledged;

      await axios.post(`${API_URL}/acknowledge-issue`, {
        serviceId,
        _id,
        acknowledged: newAcknowledgedStatus,
      });

      setServices((prevServices) =>
        prevServices.map((service) =>
          service.id === serviceId
            ? {
                ...service,
                issues: service.issues.map((issue) =>
                  issue._id === _id
                    ? { ...issue, acknowledged: newAcknowledgedStatus }
                    : issue
                ),
              }
            : service
        )
      );
    } catch (error) {
      console.error("Error toggling issue acknowledgment:", error);
    }
  };

  const handleUnacknowledgeIssue = async (serviceId, _id) => {
    // console.log(`Unacknowledging issue for service ${serviceId}, issue ID ${_id}`);
    try {
      await axios.post(`${API_URL}/acknowledge-issue`, {
        serviceId,
        _id,
        acknowledged: false,
      });

      setServices((prevServices) =>
        prevServices.map((service) =>
          service.id === serviceId
            ? {
                ...service,
                issues: service.issues.map((issue) =>
                  issue._id === _id ? { ...issue, acknowledged: false } : issue
                ),
              }
            : service
        )
      );
    } catch (error) {
      console.error("Error unacknowledging issue:", error);
    }
  };

  const fetchAcknowledgments = async (serviceId) => {
    try {
      const response = await axios.get(
        `${API_URL}/acknowledgments/${serviceId}`
      );
      if (response.status === 200 && response.data) {
        return response.data;
      } else {
        console.error("Unexpected response:", response);
        return [];
      }
    } catch (error) {
      console.error("Error fetching acknowledgments:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
      }
      return [];
    }
  };

  // const fetchServiceData = async () => {
  //   try {
  //     const response = await axios.get(`${API_URL}/services`);
  //     setServices(response.data);

  //     // Process health data for each service
  //     for (let service of response.data) {
  //       await processHealthData(service.id, service.issues);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching service data:', error);
  //   }
  // };

  const processHealthData = async (serviceId, issues) => {
    try {
      const healthStatus = issues.length > 0 ? "Unhealthy" : "Healthy";
      await axios.post(`${API_URL}/update-health-status`, {
        serviceId,
        health: healthStatus,
      });
      console.log(`Updated health status for ${serviceId} to ${healthStatus}`);
    } catch (error) {
      console.error(`Error processing health data for ${serviceId}:`, error);
    }
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="header-left">
            <img
              src={netcompanyLogo}
              alt="Netcompany logo"
              className="netcompany-logo"
            />
            <h1>Service Status Dashboard</h1>
          </div>
          <div className="menu-icon" onClick={toggleMenu}>
            <i className="fas fa-cog"></i>
          </div>
        </header>
        <Menu
          isOpen={isMenuOpen}
          toggleMenu={toggleMenu}
          refreshInterval={
            unsavedSettings?.refreshInterval ?? settings.refreshInterval
          }
          twitterLookbackPeriod={
            unsavedSettings?.twitterLookbackPeriod ??
            settings.twitterLookbackPeriod
          }
          handleTwitterLookbackChange={handleTwitterLookbackChange}
          handleRefreshIntervalChange={(e) => {
            const newValue =
              e.target.value === "" ? "" : parseInt(e.target.value);
            if (newValue === "" || (!isNaN(newValue) && newValue > 0)) {
              setUnsavedSettings((prev) => ({
                ...prev,
                refreshInterval: newValue,
              }));
            }
          }}
          saveSettings={handleSaveSettings}
          toggleDebugMode={toggleDebugMode}
          isDebugMode={isDebugMode}
        />
        <div
          className={`overlay ${isMenuOpen ? "active" : ""}`}
          onClick={toggleMenu}
        ></div>
        {isDebugMode && <div className="debug-banner">Debug Mode Enabled</div>}
        <Routes>
          <Route
            path="/"
            element={
              <Dashboard
                services={services}
                loading={loading}
                isDebugMode={isDebugMode}
                onAcknowledgeIssue={handleAcknowledgeIssue}
                onUnacknowledgeIssue={handleUnacknowledgeIssue}
                setServices={setServices}
              />
            }
          />
          <Route
            path="/service/:id"
            element={
              <ServiceDetail
                services={services}
                isDebugMode={isDebugMode}
                debugInfo={debugInfo}
                onAcknowledgeIssue={handleAcknowledgeIssue}
                onUnacknowledgeIssue={handleUnacknowledgeIssue}
                setServices={setServices}
              />
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
