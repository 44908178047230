import React from "react";
import "./ScrollingSocialMentions.css";

const ScrollingSocialMentions = ({ mentions }) => {
  return (
    <div className="scrolling-mentions-container">
      <div className="scrolling-mentions">
        {mentions.map((mention, index) => (
          <a
            key={index}
            className={`mention ${mention.source.toLowerCase()}`}
            href={getMentionUrl(mention)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="mention-source">{mention.source}</span>
            {mention.text || mention.title}
          </a>
        ))}
      </div>
    </div>
  );
};

const getMentionUrl = (mention) => {
  if (mention.source === "Twitter") {
    return `https://twitter.com/user/status/${mention.id}`;
  } else if (mention.source === "Reddit") {
    return mention.url;
  }
  return "#";
};

export default ScrollingSocialMentions;
